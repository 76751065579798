var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-container" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("v-button", {
            attrs: { text: "返回" },
            on: { click: _vm.previous },
          }),
        ],
        1
      ),
      _c(
        "import-template",
        { attrs: { uploadUrl: _vm.uploadUrl, downloadUrl: _vm.downloadUrl } },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _c("p", [_vm._v("1、支持土地信息导入；")]),
            _c("p", [
              _vm._v("2、模板中红色字体部分为必填项，灰色部分为非必填项；"),
            ]),
            _c("p", [_vm._v("3、最大支持10MB的excel文件，超过请拆分导入。")]),
            _c("div", [
              _c(
                "label",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "90px",
                    "font-weight": "700",
                  },
                },
                [_vm._v("字段说明: ")]
              ),
              _c("p", [_vm._v("1、所属项目：必填；与平台项目名称一致")]),
              _c("p", [_vm._v("2、地块代码：必填")]),
              _c("p", [_vm._v("3、地块名称：非必填")]),
              _c("p", [_vm._v("4、地块类型：必填，来自数据词典")]),
              _c("p", [_vm._v("5、确权面积：必填，数值，精确到小数点后三位")]),
              _c("p", [
                _vm._v("6、实测面积：非必填，数值，精确到小数点后三位"),
              ]),
              _c("p", [_vm._v("7、坐落（四至）：必填")]),
              _c("p", [
                _vm._v("8、是否确权：必填，只能填写（已确权，未确权 ）"),
              ]),
              _c("p", [_vm._v("9、承包方代表：必填，只能从搜索框中搜索选择")]),
              _c("p", [
                _vm._v("\n          请在excel中按以上顺序填写数据"),
                _c("br"),
                _vm._v(
                  "\n          最大支持10MB的excel文件，超过请拆分导入\n        "
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }