<template>
  <div class="import-container">
    <div class="header">
      <v-button text="返回" @click="previous"></v-button>
    </div>
    <import-template :uploadUrl="uploadUrl" :downloadUrl="downloadUrl">
      <div slot="remark">
        <p>1、支持土地信息导入；</p>
        <p>2、模板中红色字体部分为必填项，灰色部分为非必填项；</p>
        <p>3、最大支持10MB的excel文件，超过请拆分导入。</p>
        <div>
          <label style="position: absolute;left: 90px;font-weight: 700;">字段说明: </label>
          <p>1、所属项目：必填；与平台项目名称一致</p>
          <p>2、地块代码：必填</p>
          <p>3、地块名称：非必填</p>
          <p>4、地块类型：必填，来自数据词典</p>
          <p>5、确权面积：必填，数值，精确到小数点后三位</p>
          <p>6、实测面积：非必填，数值，精确到小数点后三位</p>
          <p>7、坐落（四至）：必填</p>
          <p>8、是否确权：必填，只能填写（已确权，未确权 ）</p>
          <p>9、承包方代表：必填，只能从搜索框中搜索选择</p>
          <p>
            请在excel中按以上顺序填写数据<br>
            最大支持10MB的excel文件，超过请拆分导入
          </p>
        </div>
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { uploadUrl } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl,
      downloadUrl: '/static/excel/车位导入模板.xlsx'
    }
  },
  created () {
    this.$setBreadcrumb('导入')
  },
  methods: {
    previous () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .import-container {
    text-align: left;
    background: #fff;
  }
  .header {
    position: absolute;
    top: 20px;
    right: 20px;
    :not(:last-child) {
      margin-right: 8px;
    }
  }
</style>
